<script setup>
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useAccountStore } from '@/store/account'
import UserMenu from '@/components/UserMenu.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import HomeLogo from '@/components/HomeLogo.vue'
import { useOrganizationMembership } from '@/common/organization-membership'
import { ODropdown, OIcon } from '@oruga-ui/oruga-next'
import { usePreviousRoute } from '@/common/mixins.js'
import { RECOMMENDATIONS_SUBCATEGORIES } from '@/common/cost-dashboard/recommendations.js'

const route = useRoute()
const { goTo } = useOrganizationMembership(false)
const { previousRoute } = usePreviousRoute()

/**
 * Check the expand status of sided menu tree.
 * @param {String} name Name of the current route.
 * @returns {{inventory: boolean | undefined, recommendations: boolean | undefined}}
 */
const checkExpanded = (name) => ({
  inventory:
    name?.startsWith('Inventory') ||
    name === 'ResourceConfigInventory' ||
    (name === 'ProviderAccount' && previousRoute.value?.name !== 'CloudAccounts'),
  recommendations: name?.startsWith('Recommendations')
})

const helpMenuActive = ref(false)

const expanded = ref(checkExpanded(route.name))
const inventorySubroutes = ['InventoryDiagramView', 'InventoryVirtualTags', 'ProviderAccount']

watch(
  () => route.name,
  (val) => {
    expanded.value = checkExpanded(val)
  }
)

const accountStore = useAccountStore()

const { isLoggedIn } = storeToRefs(accountStore)

const isProject = computed(() => {
  return route.name && route.name !== 'Projects' && route.name.startsWith('Project')
})

const isProjects = computed(() => {
  return route.name === 'Projects'
})

const canHaveKeyboardShortcuts = computed(() => {
  return isProject.value || isProjects.value
})

const isCompare = computed(() => route.name === 'Compare')
</script>

<template>
  <aside class="sidebar is-flex is-flex-direction-column px-3">
    <HomeLogo class="mt-5" />

    <div class="sidebar-inside holori-scrollbar">
      <ul class="menu-list mt-5">
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'Overview' }"
            @click="goTo('Overview')"
          >
            <o-icon icon="home" class="mr-3" />
            Home
          </a>
        </li>
        <li class="py-1">
          <a
            id="onboarding-cost-dashboard"
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'CostDashboard' }"
            @click="goTo('CostDashboard')"
          >
            <o-icon icon="chart-bar" class="mr-3" />
            Cost Dashboard
          </a>
        </li>
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': expanded.inventory && !inventorySubroutes.includes(route.name) }"
            @click="goTo('InventoryDashboard')"
          >
            <o-icon icon="cube" class="mr-3" />
            Inventory &amp; Visibility
            <o-icon
              :icon="expanded.inventory ? 'chevron-up' : 'chevron-down'"
              class="ml-auto"
            />
          </a>
        </li>
      </ul>
      <ul class="menu-list more" :class="{ 'more--open': expanded.inventory }">
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{
              'is-active': route.name === 'InventoryDiagramView' || route.name === 'ProviderAccount'
            }"
            @click="goTo('InventoryDiagramView')"
          >
            Infrastructure view
          </a>
        </li>
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{
              'is-active': route.name === 'ResourceConfigInventory'
            }"
            @click="goTo('ResourceConfigInventory')"
          >
            Resource config inventory
          </a>
        </li>
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'InventoryVirtualTags' }"
            @click="goTo('InventoryVirtualTags')"
          >
            Virtual Tags
          </a>
        </li>
        <!-- <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'InventoryCompliance' }"
            @click="goTo('InventoryCompliance')"
          >
            Compliance
          </a>
        </li>-->
      </ul>
      <ul class="menu-list">
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name?.startsWith('Recommendations') }"
            @click="goTo('RecommendationsOverview')"
          >
            <o-icon icon="lightbulb-outline" class="mr-3" />
            Recommendations
            <o-icon
              :icon="expanded.recommendations ? 'chevron-up' : 'chevron-down'"
              class="ml-auto"
            />
          </a>
        </li>
      </ul>
      <ul class="menu-list more" :class="{ 'more&#45;&#45;open': expanded.recommendations }">
        <li v-for="category of RECOMMENDATIONS_SUBCATEGORIES" :key="category" class="py-1">
          <a
            class="is-flex is-align-items-center is-capitalized"
            :class="{
              'is-active':
                (route.name === 'RecommendationsCategory' && route.params.category === category) ||
                route.name.toLowerCase().startsWith(`recommendations${category}`)
            }"
            @click="goTo('RecommendationsCategory', { params: { category } })"
          >
            {{ category }}
          </a>
        </li>
      </ul>
      <ul class="menu-list">
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'BusinessMetrics' }"
            @click="goTo('BusinessMetrics')"
          >
            <o-icon icon="briefcase-variant" class="mr-3" />
            Business Metrics
          </a>
        </li>
<!--        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'BudgetDashboard' }"
            @click="goTo('BudgetDashboard')"
          >
            <o-icon icon="bell-outline" class="mr-3" />
            Budget
          </a>
        </li>-->
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'ProjectsOverview' }"
            @click="goTo('ProjectsOverview')"
          >
            <o-icon icon="graph-outline" class="mr-3" />
            Project Planning
          </a>
        </li>
      </ul>
      <ul class="menu-list">
        <li class="py-1">
          <a
            class="is-flex is-align-items-center"
            :class="{ 'is-active': route.name === 'CloudAccounts' || (route.name === 'ProviderAccount' && previousRoute?.name === 'CloudAccounts') }"
            @click="goTo('CloudAccounts')"
          >
            <o-icon icon="cloud-circle-outline" class="mr-3" />
            Cloud Accounts
          </a>
        </li>
      </ul>
    </div>

    <ul class="menu-list mt-3">
      <li class="py-1">
        <o-dropdown
          v-model:active="helpMenuActive"
          aria-role="list"
          position="bottom-left"
          class="full-width help-menu"
          menu-class="help-menu-dropdown help-menu-dropdown-sidemenu"
          trigger-class="full-width"
        >
          <template #trigger="{ active }">
            <a class="is-flex is-align-items-center full-width" :class="{ 'is-active': active }">
              <o-icon icon="book-open-variant" class="mr-3" />

              Help &amp; Support

              <o-icon icon="chevron-right" class="ml-auto" />
            </a>
          </template>

          <HelpMenu
            v-model:active="helpMenuActive"
            :can-have-keyboard-shortcuts="canHaveKeyboardShortcuts"
          />
        </o-dropdown>
      </li>
    </ul>

    <hr class="separator" />

    <UserMenu v-if="(!isCompare && !isLoggedIn) || isLoggedIn" />
  </aside>
</template>

<style scoped>
.menu-list .is-active {
  background-color: var(--primary);
  position: relative;
}
.menu-list a:not(.is-active):hover {
  background-color: #8877e8;
}
.menu-label a {
  display: none;
}
.menu-label:hover a {
  display: block;
}
.more {
  opacity: 0;
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
  transform-origin: top center;
  transform: scaleY(0);
  height: 0;
  padding-left: 2.2rem;
}
.more--open {
  height: auto;
  opacity: 1;
  transform: none;
}
.separator {
  background-color: var(--secondary-dark-darkgray);
  margin: 1rem 0 0.5rem;
}
</style>
